export default {
  messages: {
    en: {
      fieldEmpty: 'Field is empty',
    },

    tw: {
      fieldEmpty: '似乎有欄位還未填寫',
    },
  },
};
