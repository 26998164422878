<template lang="pug">
.panel-evaluation-info-right.col-lg-6.col-5
  div
    .score.d-flex.align-items-center(v-for='(data, index) in score_description_left' :key='data.score')
      .score-icon
      .score-text {{ data.score }}{{ data.description }}
  .scores-low
    .score.d-flex.align-items-center(v-for='(data, index) in score_description_right' :key='data.score')
      .score-icon
      .score-text {{ data.score }}{{ data.description }}
</template>

<script type="text/javascript">

export default {
  name: 'ScoreDescription',

  data() {
    return {
      score_description_left: [
        {
          score: '100',
          description: this.$t('score') + ' ' + this.$t('addEvaluation.superGreat'),
        },
        {
          score: '90-99',
          description: this.$t('score') + ' ' + this.$t('addEvaluation.great'),
        },
        {
          score: '80-89',
          description: this.$t('score') + ' ' + this.$t('addEvaluation.nice'),
        },
      ],
      score_description_right: [
        {
          score: '70-79',
          description: this.$t('score') + ' ' + this.$t('addEvaluation.canBeImproved'),
        },
        {
          score: '60-69',
          description: this.$t('score') + ' ' + this.$t('addEvaluation.dissatisfied'),
        },
        {
          score: '0-59',
          description:
            this.$t('score') + ' ' + this.$t('addEvaluation.veryDissatisfied'),
        },
      ],
    };
  },
};
</script>

