<template>
  <!-- TODO vue-slide-bar 轉 pug element吃不到屬性狀態 -->
  <div>
    <div class="question-background-top questions-background">
      <div class="questions questions-overall">
        <div class="question">
          <div class="col-11">
            <label ref="overallLevel" class="question-label mr-2">{{ $t("addEvaluation.overallLevel") }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.overallLevel && !changedRangeList.includes('overallLevel') ? 'question-icon':'question-icon-checked']"
            />
          </div>

          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <!-- eslint-disable -->
            <vue-slide-bar
              v-model="evaluation.overallLevel"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('overallLevel')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip" slot-scope="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.overallLevel }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
        </div>
      </div>
    </div>
    <div class="questions-background">
      <div class="questions questions-consultant">
        <label class="questions-label"
          >{{ $t("addEvaluation.feelingOfConsultant") }}......</label
        ><br>

        <div class="question">
          <div class="col-11">
            <label class="question-label mr-2" ref="adviceRating">{{ $t("addEvaluation.adviceRating") }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.adviceRating && !changedRangeList.includes('adviceRating') ? 'question-icon':'question-icon-checked']"
            />
          </div>
          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <vue-slide-bar
              v-model="evaluation.adviceRating"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('adviceRating')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip" slot-scope="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.adviceRating }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
          <textarea
            v-model="evaluation.suggestionAdviceRating"
            class="question-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>

        <div class="question">
          <div class="col-11">
            <label class="question-label mr-2" ref="overallTimeRating">{{
              $t("addEvaluation.overallTimeRating")
            }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.overallTimeRating && !changedRangeList.includes('overallTimeRating') ? 'question-icon':'question-icon-checked']"
            />
          </div>

          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <vue-slide-bar
              v-model="evaluation.overallTimeRating"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('overallTimeRating')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip" slot-scope="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.overallTimeRating }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
          <textarea
            v-model="evaluation.suggestionOverallTimeRating"
            class="question-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>

        <div class="question">
          <div class="col-11">
            <label class="question-label mr-2" ref="expressTimeRating">{{
              $t("addEvaluation.expressTimeRating")
            }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.expressTimeRating && !changedRangeList.includes('expressTimeRating') ? 'question-icon':'question-icon-checked']"
            />
          </div>
          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <vue-slide-bar
              v-model="evaluation.expressTimeRating"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('expressTimeRating')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip" slot-scope="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.expressTimeRating }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
          <textarea
            v-model="evaluation.suggestionExpressTimeRating"
            class="question-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>

        <div class="consultant-rating d-flex justify-content-center align-items-center">
          <span class="consultant-rating-text"
            >{{ $t("addEvaluation.averageConsultantScore") }}：</span
          >
          <span class="consultant-rating-center"> {{consultantRating}} </span>
        </div>
      </div>
    </div>

    <div class="questions-background">
      <div class="questions">
        <label class="questions-label"
          >{{ $t("addEvaluation.feelingOfMaterial") }}......</label
        ><br>
        <div class="question">
          <div class="col-11">
            <label class="question-label mr-2" ref="materialRating">{{
              $t("addEvaluation.materialRating")
            }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.materialRating && !changedRangeList.includes('materialRating') ? 'question-icon':'question-icon-checked']"
            />
          </div>
          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <vue-slide-bar
              v-model="evaluation.materialRating"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('materialRating')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip" slot-scope="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.materialRating }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
          <textarea
            v-model="evaluation.suggestionMaterialRating"
            class="question-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>
      </div>
    </div>

    <div class="questions-background">
      <div class="questions">
        <label class="questions-label"
          >{{ $t("addEvaluation.feelingOfCommunicationStatus") }}......</label
        ><br>

        <div class="question">
          <div class="col-11">
            <label class="question-label mr-2" ref="communicationRating">{{
              $t("addEvaluation.communicationRating")
            }}</label>
            <font-awesome-icon
              icon="check-circle"
              :class="[!specificClassEvaluation.communicationRating && !changedRangeList.includes('communicationRating') ? 'question-icon':'question-icon-checked']"
            />
          </div>
          <div
            class="question-bar col-sm-12 col-md-11 justify-content-center overall_level"
          >
            <vue-slide-bar
              v-model="evaluation.communicationRating"
              :range="sliderOptions.marks"
              :processStyle="sliderOptions.processStyle"
              :paddingless="true"
              @dragEnd="changeRange('communicationRating')"
            >
              <!-- eslint-disable -->
              <template slot="tooltip">
                <div class="tool-button"></div>
                <div class="slide-bar-tooltip">
                  <div class="tooltips">
                    {{ evaluation.communicationRating }}
                  </div>
                </div>
              </template>
            </vue-slide-bar>
          </div>
          <textarea
            v-model="evaluation.suggestionCommunicationRating"
            class="question-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>
      </div>
    </div>

    <div class="questions-background">
      <div class="questions">
        <div class="question">
          <div class="col-12">
            <label class="question-label">{{ $t("addEvaluation.feedback") }}</label>
          </div>
          <textarea
            v-model="evaluation.feedback"
            class="question-input feedback-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
        </div>

        <div class="question">
          <div class="col-11">
            <label class="question-label">{{
              $t("addEvaluation.suggestionForConsultant")
            }}</label>
          </div>
          <textarea
            v-model="evaluation.hbSuggestion"
            class="question-input feedback-input"
            rows="3"
            cols="65"
            :placeholder="$t('addEvaluation.opinionFillinRequest')"
          ></textarea>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import vueSlideBar from 'vue-slide-bar'; // TODO 可另外包成元件 By Asa
import evaluationsContants from '@/constants/evaluations';
import { alertHtmlMessage } from '@/utils/sweetAlert.js';
import i18n from './lang';
import camelcaseKeys from 'camelcase-keys';

export default {
  name: 'VipClassEvaluation',

  components: {
    vueSlideBar,
  },

  props: {
    // eslint-disable-next-line
    specificClassEvaluation: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  i18n,

  data() {
    return {
      // TODO vueSlideBar option 可另外包成元件 By Asa
      sliderOptions: {
        processStyle: {
          backgroundColor: '#1255a0',
          height: '8px',
          width: '100px',
        },

        marks: [
          { label: '0' },
          { label: '10' },
          { label: '20' },
          { label: '30' },
          { label: '40' },
          { label: '50' },
          { label: '60' },
          { label: '70' },
          { label: '80' },
          { label: '90' },
          { label: '100' },
        ],
      },
      evaluation: {
        /* eslint-disable */
        overallLevel: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(1)我對於本堂課的整體滿意程度
        adviceRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(2)顧問有即時給予我糾正與回饋
        suggestionAdviceRating: '', //(2-2)顧問有給予適當回饋(input)
        overallTimeRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(3)顧問的教學內容時間分配與掌握
        suggestionOverallTimeRating: '', //(3-2)顧問的教學內容時間分配與掌握(input)
        consultantRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(1)顧問平均分數
        expressTimeRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(4)我有被分配到適當的表達時間
        suggestionExpressTimeRating: '', //(4-2)我有被分配到適當的表達時間(input)
        materialRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(5)教材滿意度
        suggestionMaterialRating: '', //(5-2)教材滿意度(input)
        communicationRating: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //(6)通訊狀況滿意度
        suggestionCommunicationRating: '', //(6-2)通訊狀況滿意度(input)
        feedback: '', //(7)與客服的悄悄話
        hbSuggestion: '', //(8)給顧問的建議
        upgradeApplication: evaluationsContants.ADD_EVALUATION.DEFAULT_SCORE, //之前會帶的舊參數(帶0即可)
      },
      changedRangeList: [],
    };
  },

  computed: {
    consultantRating(){
      // if(this.evaluation.consultant_rating){
      //   return this.evaluation.consultant_rating;
      // } else {
        // 是否可直接輸出
        return Math.round(
          ((this.evaluation.adviceRating + this.evaluation.overallTimeRating + this.evaluation.expressTimeRating) / 3) * 100
        ) / 100;
      // }
    }
  },

  watch: {
    consultantRating(value){
      this.evaluation.consultantRating = value;
    }
  },

  created() {
    // (!this.specificClassEvaluation.ca_id) ?  this.$router.push({ path: '/' }) : '';
  },

  mounted() {
    this.setEvaluationValue();
  },

  methods: {
    setEvaluationValue(){
      if(this.specificClassEvaluation !== null){
        for (const [key, value] of Object.entries(camelcaseKeys(this.specificClassEvaluation))) {
          this.evaluation[key] = value;
        }
      }
    },

    changeRange(val){
      if(!this.changedRangeList.includes(val)){
        this.changedRangeList.push(val);
      }
    },

    async checkAllRateChanged(){
      const UnfilledFieldsInfo = this.getUnfilledFieldsInfo();
      if (UnfilledFieldsInfo.emptyFieldContent !== ''){
        const errorMessageContentStyle= 'text-align: left; margin:0 auto; width: 16em;';
        const errorMessage = `<div style="${errorMessageContentStyle}">${UnfilledFieldsInfo.emptyFieldContent}</div>`,
        errorTitle = `${this.$t('fieldEmpty')} ...`,
        confirmBtn = this.$t('confirm');
        await alertHtmlMessage(errorTitle, 'error', errorMessage, confirmBtn);
        this.$refs[UnfilledFieldsInfo.scrollPosition].scrollIntoView({behavior: 'smooth', block: 'center'});
        return false;
      }
      return true;
    },
    // emptyFieldContent:整理所有未填欄位 scrollPosition:第一個未填欄位位置
    getUnfilledFieldsInfo(){
      const nonexistentValue = -1;
      const allRate = [evaluationsContants.ADD_EVALUATION.FIELD.OVERALL_LEVEL,
        evaluationsContants.ADD_EVALUATION.FIELD.ADVICE_RATING,
        evaluationsContants.ADD_EVALUATION.FIELD.OVERALL_TIME_RATING,
        evaluationsContants.ADD_EVALUATION.FIELD.EXPRESS_TIME_RATING,
        evaluationsContants.ADD_EVALUATION.FIELD.MATERIAL_RATING,
        evaluationsContants.ADD_EVALUATION.FIELD.COMMUNICATION_RATING
      ];
      let emptyFieldContent = '', scrollPosition = null, index = 0;
      allRate.forEach(element => {
        if(this.changedRangeList.indexOf(element) === nonexistentValue){
          if(!scrollPosition){
            scrollPosition = element;
          }
          emptyFieldContent += `${++index}.${this.$t('addEvaluation.' + element)} <br>`;
        }
      });
      return {emptyFieldContent: emptyFieldContent, scrollPosition: scrollPosition};
    },

  },
};
</script>
