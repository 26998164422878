<template lang="pug">
  .are-evaluation-card
    .blk-evaluation-head
      h3.h3-evaluation-title {{ specificClassroomBasicInfo.material.title }}
      br
      .panel-evaluation-info.d-flex.justify-content-between
        .panel-evaluation-info-left
          h5.h5-evaluation-title
            | {{ $t("addEvaluation.thanksForFeedbackAndOpinion") }}
            br
          //- 左上角 顧問照片/顧問名字/課程時間
          consultant-with-class-time(:time='specificClassroomBasicInfo.class.time' :consultant='specificClassroomBasicInfo.consultant')
        //- 右上角的分數區間介紹表
        score-description
      br
    .blk-evaluation-body
      //- 填寫拉霸的區塊
      vip-class-evaluation(ref='vip-class-evaluation' :specificClassEvaluation='specificClassroomBasicInfo.evaluation')
    .blk-evaluation-footer.d-flex.justify-content-center
      button.btn.btn-outline-primary(type='button' @click='() => {this.$router.go(-1);}')
        | {{ $t("router_back") }}
      button.btn.btn-primary(type='button' :disabled="isDisabled" @click='sendEvaluationData()')
        span(v-if='canEdit()') {{ $t("edit") }}
        span(v-else='') {{ $t("send") }}
</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import httpCodeConstants from '@/constants/httpCode';
import { alertSuccessMessage, alertFailedMessage } from '@/utils/sweetAlert.js';
import lioshutanApi from '@/api';
import consultantWithClassTime from '@/views/dojo/evaluations/addEvaluation/consultantWithClassTime';
import scoreDescription from '@/views/dojo/evaluations/addEvaluation/scoreDescription';
import vipClassEvaluation from '@/components/class/evaluations/vipEvaluateClass';

export default {
  name: 'VipAddEvaluation',

  components: {
    consultantWithClassTime,
    scoreDescription,
    vipClassEvaluation,
  },

  data() {
    return {
      isDisabled: false,
    };
  },

  computed: {
    ...mapState({
      specificClassroomBasicInfo: (state) => state.classroom.specificClassroomBasicInfo,
    }),
  },

  watch: {},

  created() {
    // 若無資料直接回到學習軌跡
    (!this.specificClassroomBasicInfo.class.id) ? this.$router.push({ name: 'classRecords' }) : '';
  },

  methods: {
    canEdit(){
      return (this.specificClassroomBasicInfo.overall_level || this.specificClassroomBasicInfo.overall_level === 0);
    },

    async sendEvaluationData(){
      // 防呆: 防止連續送出
      if (this.isDisabled) {
        return;
      }

      const checkResult = await this.$refs['vip-class-evaluation'].checkAllRateChanged();
      if (checkResult){
        this.isDisabled = true;
        try {
          const params = {
            classApplicationId: this.specificClassroomBasicInfo.class.id,
            classroomId: this.specificClassroomBasicInfo.class.room_id,
            ...this.$refs['vip-class-evaluation'].evaluation,
          };
          const response = await lioshutanApi.vip.sendEvaluationData(params);
          if (response.status === httpCodeConstants.SUCCESS){
            alertSuccessMessage(`${this.$t('add')}${this.$t('success')}`);
            this.$router.go(-1);
            window.scrollTo(0, 0);
          }
        } catch (error) {
          alertFailedMessage(error.response.data.message);
        } finally {
          this.isDisabled = false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/class/evaluation";
</style>
