<template>
  <div class="d-flex align-items-center">
    <img
      :src="consultant.image"
      alt=""
      class="hb-image"
    >
    <div class="">
      <span class="txt-evaluation-classtime d-flex align-items-center">
        <div class="icon-evaluation" />
        <div>{{ time }}</div>
      </span>
      <span class="txt-evaluation-hbname d-flex align-items-center">
        <div class="icon-evaluation icon-evaluation-circle" />
        <div>{{ consultant.name }}</div>
      </span>
    </div>
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'ConsultantWithClassTime',

  props: {
    // eslint-disable-next-line
    time: {
      type: String,
      default: function() {
        return '';
      },
    },

    consultant: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
    };
  },
};
</script>

