export default {
  ADD_EVALUATION: {
    DEFAULT_SCORE: 80,
    FIELD: {
      OVERALL_LEVEL: 'overallLevel',
      ADVICE_RATING: 'adviceRating',
      OVERALL_TIME_RATING: 'overallTimeRating',
      EXPRESS_TIME_RATING: 'expressTimeRating',
      MATERIAL_RATING: 'materialRating',
      COMMUNICATION_RATING: 'communicationRating',
    },
  },
};
